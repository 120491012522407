import {
  Hardware,
  useHardwareMapQuery,
  useLightningMapGeoJsonQuery,
} from "../../../../Services/API";
import { Map, Marker } from "maplibre-gl";
import { Source, Layer } from "react-map-gl";
import { Viewport } from "../Util";

// Don't love the params we have to pass in. Could cause issues

// const geojson:  GeoJSON.FeatureCollection<GeoJSON.Geometry>  = {
//     type: 'FeatureCollection',
//     features: [
// 	  {type: 'Feature', geometry: {type: 'Point', coordinates: [-96.8518, 32.80366]}, properties: {
// 		  Age: 250,
// 	  }},
// 	  {type: 'Feature', geometry: {type: 'Point', coordinates: [-96.8418, 32.80366]}, properties: {
// 		  Age: 899,
// 	  }},
// 	  {type: 'Feature', geometry: {type: 'Point', coordinates: [-96.8318, 32.80366]}, properties: {
// 		  Age: 1200,
// 	  }},
//     ]
// };

const ageAlive: number = 960;
const iconSize: number = 0.75;

/**
 * returns a parsed string
 * @param {string} str
 * @returns {string}
 */
function searchableString(str: string): string {
  return str ? str.replaceAll(/['"-.,]/gi, "").toUpperCase() : "";
}

/**
 *
 */
export interface HardwareLayerProps {
  viewport: Viewport;
  mapRef: Map;
  filters: any;
  searchVal: string;
}

/**
 * returns a jsx element
 * @param {HardwareLayerProps}
 * @returns {JSX.Element}
 */
export function HardwareLayer({
  viewport,
  mapRef,
  filters,
  searchVal,
}: HardwareLayerProps) {
  let { data } = useHardwareMapQuery(null, {
    refetchOnReconnect: true,
    refetchOnFocus: true,
    pollingInterval: 1000 * 60 * 2,
  }) as any;

  if (data?.features && searchVal?.length > 2)
    data = {
      ...data,
      features: data.features.filter((feature: any) => {
        return (
          searchableString(feature.properties.Name).includes(
            searchableString(searchVal)
          ) ||
          searchableString(feature.properties.CustomerName).includes(
            searchableString(searchVal)
          )
        );
      }),
    };


  const isAgeUnhealthy = [
    "any",
    [
      "all",
      ["!=", ["get", "ModelId"], "OWS"],
      [">=", ["get", "wxAge"], ageAlive],
    ],
    [">=", ["get", "hwAge"], ageAlive],
  ];
  const isVoltLow = ["<", ["get", "Voltage"], 12.0];
  // const isAgeHealthy = ['all', ['>=', ['get', 'hwAge'], 0], ['<', ['get', 'hwAge'], 900], ['>=', ['get', 'wxAge'], 0], ['<', ['get', 'wxAge'], 900]];

  const owxLayer: any = {
    id: "owx-layer",
    type: "symbol",
    source: "hardware",
    filter: [
      "all",
      ["!", ["has", "point_count"]],
      ["==", ["get", "ModelId"], "OWX"],
      [
        "any",
        ["all", isAgeUnhealthy, filters.deg],
        ["all", isVoltLow, filters.pwr],
        [
          "all",
          ["!", isAgeUnhealthy],
          ["!", isVoltLow],
          filters.hlth,
        ],
      ],
    ],
    layout: {
      "icon-image": [
        "case",
        isAgeUnhealthy,
        "uh_owx",
        isVoltLow,
        "lv_owx",
        "h_owx",
      ],
      "icon-size": iconSize,
      "icon-padding": 0,
      "icon-allow-overlap": true,
    },
  };
  const wxLayer: any = {
    id: "wx-layer",
    type: "symbol",
    source: "hardware",
    filter: [
      "all",
      ["!", ["has", "point_count"]],
      ["==", ["get", "ModelId"], "WXS"],
      [
        "any",
        ["all", isAgeUnhealthy, filters.deg],
        ["all", isVoltLow, filters.pwr],
        [
          "all",
          ["!", isAgeUnhealthy],
          ["!", isVoltLow],
          filters.hlth,
        ],
      ],
    ],
    layout: {
      "icon-image": [
        "case",
        isAgeUnhealthy,
        "uh_wx",
        isVoltLow,
        "lv_wx",
        "h_wx",
      ],
      "icon-size": iconSize,
      "icon-padding": 0,
      "icon-allow-overlap": true,
    },
  };

  const owsLayer: any = {
    id: "ows-layer",
    type: "symbol",
    source: "hardware",
    filter: [
      "all",
      ["!", ["has", "point_count"]],
      ["==", ["get", "ModelId"], "OWS"],
      [
        "any",
        ["all", isAgeUnhealthy, filters.deg],
        ["all", isVoltLow, filters.pwr],
        [
          "all",
          ["!", isAgeUnhealthy],
          ["!", isVoltLow],
          filters.hlth,
        ],
      ],
    ],
    layout: {
      "icon-image": [
        "case",
        isAgeUnhealthy,
        "uh_ows",
        isVoltLow,
        "lv_ows",
        "h_ows",
      ],
      "icon-size": iconSize,
      "icon-padding": 0,
      "icon-allow-overlap": true,
    },
  };

  const circleClusterLayer: any = {
    type: "circle",
    id: "hardware-clusters-circle",
    source: "hardware",
    filter: ["has", "point_count"],
    paint: {
      "circle-radius": 11,
      "circle-color": "#efefef",
      "circle-stroke-opacity": 0.3,
      "circle-stroke-width": 2,
      "circle-translate": [0, -8],
    },
  };

  const clusterCountLayer: any = {
    id: "hardware-cluster-count",
    type: "symbol",
    source: "hardware",
    filter: ["has", "point_count"],
    layout: {
      "text-offset": [0, -0.75],
      "text-field": "{point_count_abbreviated}",
      "text-font": ["Proxima Nova"],
      "text-size": 10,
      "text-allow-overlap": true,
    },
  };

  const clusterLayer: any = {
    layout: {
      "icon-image": "owx",
      "icon-size": iconSize * 1.2,
      "icon-padding": 0,
      "icon-allow-overlap": true,
      ///Comment out to remove text.
      //   'text-field': '{point_count_abbreviated}',
      //   'text-font': ['Proxima Nova'],
      //   'text-size': 10
      ///
    },
    type: "symbol",
    id: "hardware-clusters-icon",
    source: "hardware",
    filter: ["has", "point_count"],
    paint: {
      "icon-color": "#FF8C00",
      "icon-halo-color": "#000",
      "icon-halo-width": 3,
      //   'icon-halo-blur': 0,
    },
  };

  return data ? (
    <Source id="hardware" type="geojson" data={data}>
      <Layer {...clusterLayer} />
      <Layer {...circleClusterLayer} />
      <Layer {...clusterCountLayer} />

      {filters.ows && <Layer {...owsLayer} />}
      {filters.owx && <Layer {...owxLayer} />}
      {filters.wxs && <Layer {...wxLayer} />}
    </Source>
  ) : (
    <></>
  );
}

export default HardwareLayer;

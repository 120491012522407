import { CSSProperties } from "react";
import { _useMapControl as useMapControl } from "react-map-gl";
import { Box, Grow, Typography, useMediaQuery, useTheme, Checkbox, ThemeProvider } from "@mui/material";
import { PWColors } from "../../../../Theme/PWColors";
import BaseControl from "./BaseControl";
import { SearchField } from "../../../Base";
import { LightTheme } from "../../../../Theme/LightTheme";

interface LegendControlProps {
  onClick: React.MouseEventHandler;
  show: boolean;
  filters: any;
  setFilters: Function;
  searchVal: string;
  setSearchVal: Function;
}

export function LegendControl(props: LegendControlProps) {
  const { onClick, show, filters, setFilters, searchVal, setSearchVal } = props;

  const { containerRef } = useMapControl({
    onDragStart: (evt: any) => {
      evt.stopPropagation();
    },
    onDoubleClick: (evt: any) => {
      evt.stopPropagation();
    },
    onClick: (evt: any) => {
      evt.stopPropagation();
    },
  });

  const handleSearch = (str: string) => {
    setSearchVal(str);
  };

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));

  // essentially need position absolute if being used standalone, i.e, not inside the group.
  const style: CSSProperties = {
    right: 0,
    top: 0,
    position: "absolute",
    backgroundColor: "#fff",
    borderRadius: 100,
  };

  var controlClass: string = `mapbroxgl-ctrl mapboxgl-ctrl-group`;

  return (
    <ThemeProvider theme={LightTheme}>
      <div style={{ marginBottom: "10px" }}>
        <Grow in={show}>
          {isMd ? (
            <div style={{ ...style, borderRadius: 8, top: 10, right: 10 }} className={controlClass} ref={containerRef}>
              <Box
                alignItems="left"
                justifyContent="space-around"
                display="flex"
                flexDirection="column"
                padding={2}
                width={225}
              >
                <Typography variant="h5" align="left" color={PWColors.light.base[900]}>
                  Type
                </Typography>
                <Box width="100%" display="flex" justifyContent="flex-start" flexDirection="row" alignItems="center">
                  <Checkbox
                    sx={{ cursor: "pointer", padding: 0, paddingRight: 1 }}
                    checked={filters.wxs}
                    onChange={() => setFilters({ ...filters, wxs: !filters.wxs })}
                  ></Checkbox>
                  <img src="/hardware/blue_circle_pin.png" alt="b_circle_pin" style={{ width: "24px" }} />
                  <Typography color={PWColors.light.base[900]}>WXS</Typography>
                </Box>
                <Box width="100%" display="flex" justifyContent="flex-start" flexDirection="row" alignItems="center">
                  <Checkbox
                    sx={{ cursor: "pointer", padding: 0, paddingRight: 1 }}
                    checked={filters.ows}
                    onChange={() => setFilters({ ...filters, ows: !filters.ows })}
                  ></Checkbox>
                  <img src="/hardware/blue_square_pin.png" alt="b_circle_pin" style={{ width: "24px" }} />
                  <Typography color={PWColors.light.base[900]}>OWS</Typography>
                </Box>
                <Box width="100%" display="flex" justifyContent="flex-start" flexDirection="row" alignItems="center">
                  <Checkbox
                    sx={{ cursor: "pointer", padding: 0, paddingRight: 1 }}
                    checked={filters.owx}
                    onChange={() => setFilters({ ...filters, owx: !filters.owx })}
                  ></Checkbox>
                  <img src="/hardware/blue_diamond_pin.png" alt="b_circle_pin" style={{ width: "24px" }} />
                  <Typography color={PWColors.light.base[900]}>OWX</Typography>
                </Box>

                <Typography variant="h5" align="left" color={PWColors.light.base[900]}>
                  Status
                </Typography>

                <Box width="100%" display="flex" justifyContent="flex-start" flexDirection="row" alignItems="center">
                  <Checkbox
                    sx={{ cursor: "pointer", padding: 0, paddingRight: 1 }}
                    checked={filters.hlth}
                    onChange={() => setFilters({ ...filters, hlth: !filters.hlth })}
                  ></Checkbox>
                  <Typography color={PWColors.light.base[900]}>Healthy</Typography>
                </Box>
                
                <Box width="100%" display="flex" justifyContent="flex-start" flexDirection="row" alignItems="center">
                  <Checkbox
                    sx={{ cursor: "pointer", padding: 0, paddingRight: 1 }}
                    checked={filters.deg}
                    onChange={() => setFilters({ ...filters, deg: !filters.deg })}
                  ></Checkbox>
                  <Typography color={PWColors.light.base[900]}>Unhealthy</Typography>
                </Box>

                <Box width="100%" display="flex" justifyContent="flex-start" flexDirection="row" alignItems="center">
                  <Checkbox
                    sx={{ cursor: "pointer", padding: 0, paddingRight: 1 }}
                    checked={filters.pwr}
                    onChange={() => setFilters({ ...filters, pwr: !filters.pwr })}
                  ></Checkbox>
                  <Typography color={PWColors.light.base[900]}>Low voltage</Typography>
                </Box>
                <Typography variant="h5" align="left" color={PWColors.light.base[900]}>
                  Search
                </Typography>
                <SearchField value={searchVal} handleSearch={handleSearch}></SearchField>
              </Box>
            </div>
          ) : (
            <div style={{ ...style, borderRadius: 100 }} className={controlClass} ref={containerRef}>
              <Box display="flex" flexDirection="column" paddingX={5} paddingY={2}>
                <Box
                  alignItems="center"
                  display="flex"
                  flexDirection="row"
                  paddingLeft={2}
                  paddingRight={2}
                  height={40}
                  gap="1em"
                >
                  <Typography variant="h5" align="right" width={75} color={PWColors.light.base[900]}>
                    Type:
                  </Typography>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Checkbox
                      sx={{ cursor: "pointer", padding: 0 }}
                      checked={filters.wxs}
                      onChange={() => setFilters({ ...filters, wxs: !filters.wxs })}
                    ></Checkbox>
                    <img src="/hardware/blue_circle_pin.png" alt="b_circle_pin" style={{ width: "24px" }} />
                    <Typography color={PWColors.light.base[900]}>WXS</Typography>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Checkbox
                      sx={{ cursor: "pointer", padding: 0 }}
                      checked={filters.ows}
                      onChange={() => setFilters({ ...filters, ows: !filters.ows })}
                    ></Checkbox>
                    <img src="/hardware/blue_square_pin.png" alt="b_circle_pin" style={{ width: "24px" }} />
                    <Typography color={PWColors.light.base[900]}>OWS</Typography>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Checkbox
                      sx={{ cursor: "pointer", padding: 0 }}
                      checked={filters.owx}
                      onChange={() => setFilters({ ...filters, owx: !filters.owx })}
                    ></Checkbox>
                    <img src="/hardware/blue_diamond_pin.png" alt="b_circle_pin" style={{ width: "24px" }} />
                    <Typography color={PWColors.light.base[900]}>OWX</Typography>
                  </div>
                </Box>

                <Box
                  alignItems="center"
                  display="flex"
                  flexDirection="row"
                  paddingLeft={2}
                  paddingRight={2}
                  height={40}
                  gap=".75em"
                >
                  <Typography variant="h5" align="right" width={75} color={PWColors.light.base[900]}>
                    Status:
                  </Typography>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Checkbox
                      sx={{ cursor: "pointer", padding: 0 }}
                      checked={filters.hlth}
                      onChange={() => setFilters({ ...filters, hlth: !filters.hlth })}
                    ></Checkbox>
                    <Typography color={PWColors.light.base[900]} width="max-content">
                      Healthy
                    </Typography>
                  </div>

                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Checkbox
                      sx={{ cursor: "pointer", padding: 0 }}
                      checked={filters.deg}
                      onChange={() => setFilters({ ...filters, deg: !filters.deg })}
                    ></Checkbox>
                    <Typography color={PWColors.light.base[900]} width="max-content">
                      Unhealthy
                    </Typography>
                  </div>

                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Checkbox
                      sx={{ cursor: "pointer", padding: 0 }}
                      checked={filters.pwr}
                      onChange={() => setFilters({ ...filters, pwr: !filters.pwr })}
                    ></Checkbox>
                    <Typography color={PWColors.light.base[900]} width="max-content">
                      Low voltage
                    </Typography>
                  </div>
                </Box>

                <Box
                  alignItems="center"
                  display="flex"
                  flexDirection="row"
                  paddingLeft={2}
                  paddingRight={2}
                  height={50}
                  gap=".75em"
                >
                  <Typography variant="h5" align="right" width={75} color={PWColors.light.base[900]}>
                    Search:
                  </Typography>

                  <SearchField value={searchVal} handleSearch={handleSearch}></SearchField>
                </Box>
              </Box>
            </div>
          )}
        </Grow>

        <BaseControl label="Legend" onClick={onClick}>
          <img src="/hardware/blue_diamond_pin.png" alt="b_circle_pin" style={{ width: "24px" }} />
        </BaseControl>
      </div>
    </ThemeProvider>
  );
}

export default LegendControl;
